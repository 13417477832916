import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../../pages/Page';
import { withRouter } from 'react-router-dom';
import HtmlText from '../../../components/HtmlText';

import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { loadArticle } from '../actions';
import { WizardTitle, PageHeight60, scrollToTop } from '../../../components/Utils';

const frontload = async (props) => {
  const id = props.match.params.id;
  const searchParams = new URLSearchParams(props.location.search);
  const isPublic = searchParams.get("public");
  const categoryName = searchParams.get("category");
  // await props.getArticle({ id/* , isPrivate: false, categoryName: 'General' */ });
  await props.getArticle({ id, isPrivate: (isPublic !== "true"), categoryName });
};
const mapStateToProps = (state) => {
  return {
    article: state.news.article,
    error: state.news.error
  };
};
const mapDispatchToProps = (dispatch) => ({
  getArticle: (...args) => dispatch(loadArticle(...args))
});

class NewsDetails extends React.Component {
  static propTypes = {
    article: PropTypes.shape({}),
    getArticle: PropTypes.func
  }
  static defaultProps = {
    className: 'margin-big-top'
  }
  componentDidMount() {
    console.log("NewsDetails.componentDidMount()");
    scrollToTop();
  }
  componentDidUpdate() {
    console.log("NewsDetails.componentDidUpdate()");
    const { error, history } = this.props;
    if (error) {
      console.error("Article not found => returning to previous page ...")
      setTimeout(() => history.goBack(), 2000);
    }
  }
  render() {
    const { article } = this.props;
    console.log('NewsDetails.render()', this.props);
    if (!article) return null;
    return (
      <Page
        id="newsDetailsPage"
        className="news-details-page"
        title={article.title}
        description={article.subtitle || article.title}
      >
        <WizardTitle model={{ name: article.title }} />
        <div className="container">
          <PageHeight60
            id="news-beitrag"
            className="w3-border margin-big-top pad-big"
            isUnterWizardTitle
          >
            {article.image && (<img className="image" src={article.image} alt="Bild" />)}
            {/* {article.subtitle && (<p>{article.subtitle}</p>)} */}
            <HtmlText text={article.body} />
          </PageHeight60>
        </div>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(
    frontloadConnect(frontload, {
      onMount: true,
      onUpdate: false
    })(NewsDetails)
  )
);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SecondaryButtonLink } from "../../../../components/Buttons";
import { PermissionResource, Resources } from "../../../Permissions";

const mapStateToProps = (state) => {
  return {
    adConnections: state.task.adConnections,
    ptConnections: state.task.ptConnections,
    delphiConnections: state.task.delphiConnections,
    msAuthenticatorTasks: state.task.msAuthenticatorTasks,
  };
};
const mapDispatchToProps = null;

class TaskDashboard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    holderClassName: PropTypes.string,
    adConnections: PropTypes.array,
    ptConnections: PropTypes.array,
    delphiConnections: PropTypes.array,
    msAuthenticatorTasks: PropTypes.array,
  };
  static defaultProps = {
    className: "",
    holderClassName: null,
  };
  renderItem = (label, value) => {
    const labelClassName = value > 0 ? "w3-text-green" : "";
    return (
      <li className="no-pad-tb">
        <span className={`w3-right ${labelClassName}`}>{value || "Keine"}</span>
        <span>{label}</span>
      </li>
    );
  };
  render() {
    const {
      className,
      holderClassName,
      adConnections,
      ptConnections,
      delphiConnections,
      msAuthenticatorTasks,
    } = this.props;
    console.log("ITTaskDashboard.render()", this.props);
    let markup = (
      <SecondaryButtonLink
        id="itConnectionTasks"
        className={className}
        to="/aufgaben/it-zugaenge"
        style={{ minHeight: "250px" }}
      >
        <h3 className="no-margin alg-center pad-big-tb ">IT-Zugänge</h3>
        <ul className="w3-ul">
          <PermissionResource
            resource={Resources.Tasks.ITConnections.ADConnections}
          >
            {this.renderItem("Vodafone Account", adConnections.length)}
          </PermissionResource>
          <PermissionResource
            resource={Resources.Tasks.ITConnections.PTConnections}
          >
            {this.renderItem("iProv-Tool", ptConnections.length)}
          </PermissionResource>
          <PermissionResource
            resource={Resources.Tasks.ITConnections.DelphiConnections}
          >
            {this.renderItem("Delphi", delphiConnections.length)}
          </PermissionResource>
          <PermissionResource
            resource={Resources.Tasks.ITConnections.MSAuthenticator}
          >
            {this.renderItem("MS Authenticator", msAuthenticatorTasks.length)}
          </PermissionResource>
        </ul>
      </SecondaryButtonLink>
    );
    if (holderClassName) {
      markup = <div className={holderClassName}>{markup}</div>;
    }
    return markup;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskDashboard);

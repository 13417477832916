import React from "react";
import PropTypes from "prop-types";
import { InputText, Label, Dropdown } from "../../../../components/Inputs";
import Connection from "./Connection";
import {
  STATUS_LABELS,
  statusIsEditable,
  STATUS_IDS,
  ACTION_IDS,
} from "../../../../components/Utils";
import { PrimaryButton, SecondaryButton } from "../../../../components/Buttons";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import { PermissionResource, Resources } from "../../../Permissions";
import ADConnectionListForm from "./ADConnectionListForm";

const YesNoOptions = [
  { label: "Ja", value: "Ja" },
  { label: "Nein", value: "Nein" },
];

export default class ITConnectionForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({}),
    kdlCompanyList: PropTypes.array,
    userCompany: PropTypes.object,
    canReadAdminData: PropTypes.bool,
    rows: PropTypes.number,
    vfEmailPermission: PropTypes.shape({}),
  };
  static defaultProps = {
    className: "",
    model: {
      ptConnection: "",
      ptUsername: "",
      ptPassword: "",
      adConnection: "",
      adUsername: "",
      adPassword: "",
      adCellNumber: "",
      delphiConnections: [],
    },
    companyList: [],
    kdlCompanyList: [],
    rows: 5,
  };
  constructor(props) {
    super(props);
    const model = props.model || {};
    const defaultStatusId = STATUS_IDS.NEW;
    // const adStatus_id = (model.adStatus_id && model.adStatus_id !== "") ? model.adStatus_id : defaultStatusId;
    const ptStatus_id = (model.ptStatus_id && model.ptStatus_id !== "") ? model.ptStatus_id : defaultStatusId;
    this.state = {
      adConnection: model.adConnection || "",
      // adUsername: model.adUsername || "",
      // adPassword: model.adPassword || "",
      // adAction_id: model.adAction_id || "",
      // adStatus_id,
      // adStatus: model.adStatus || STATUS_LABELS[model.adStatus_id],
      ptConnection: model.ptConnection || "",
      ptUsername: model.ptUsername || "",
      ptPassword: model.ptPassword || "",
      ptAction_id: model.ptAction_id || "",
      ptStatus_id,
      ptStatus: model.ptStatus || STATUS_LABELS[ptStatus_id],
      error: {
        ptConnection: null,
      },
    };
  }
  validate = () => {
    let model = {};
    // vodafone account 
    const adModel = this.adConnection.getModel();
    model = {...model, ...adModel};
    // pt connection
    const { error, adConnection, ...ptModel } = this.state;
    // const ptModel = this.ptConnection.getModel();
    model = {...model, ...ptModel};
      // const { error, ...model } = this.state;
      // // check if all values are set
      // let isValid = true;
      // Object.keys(model).forEach((key) => {
      //   if (error[key] !== undefined && model[key] === "") {
      //     error[key] = true;
      //     isValid = false;
      //   }
      // });
    // delphi connections
    const connections = this.connections.getModel();
    model.delphiConnections = connections;

    const pModel = this.props.model || {};
    const { id, ad_id, pt_id } = pModel;
    model.id = id;
    model.ad_id = ad_id;
    model.pt_id = pt_id;
    return model;
  };
  getModel = () => {
    const model = this.validate();
    return model;
  };
  executeChange = (connectionType, actionId, statusId) => {
    const prefix = connectionType.toLowerCase();
    this.setState({
      [`${prefix}Action_id`]: actionId,
      [`${prefix}Status_id`]: statusId,
      [`${prefix}Status`]: STATUS_LABELS[statusId],
    });
  };
  onChange = (connectionType) => {
    const title = `${connectionType}-Zugang ändern`;
    const text = `Wollen Sie eine Verlängerung des ${connectionType}-Zugangs wirklich beantragen?`;
    const execFunc = () =>
      this.executeChange(
        connectionType,
        ACTION_IDS.UPDATE,
        STATUS_IDS.UPDATING
      );
    this.confirm.show(true, { title, text }, execFunc);
  };
  onDelete = (connectionType) => {
    const title = `${connectionType}-Zugang löschen`;
    const text = `Wollen Sie eine Löschung des ${connectionType}-Zugangs wirklich beantragen?`;
    const execFunc = () =>
      this.executeChange(
        connectionType,
        ACTION_IDS.DELETE,
        STATUS_IDS.DELETING
      );
    this.confirm.show(true, { title, text }, execFunc);
  };
  onADConnectionChange = (adConnection) => {
    this.setState({ adConnection });
  }
  renderConnectionBtns = (connectionType, model) => {
    const idAttr = `${connectionType.toLowerCase()}_id`;
    const statusAttr = `${connectionType.toLowerCase()}Status_id`;
    return (
      <div>
        {connectionType !== "AD" && (
          <PrimaryButton
            size="small"
            disabled={!statusIsEditable(model[statusAttr], model[idAttr])}
            onClick={() => this.onChange(connectionType)}
          >
            {`Ändern`}
          </PrimaryButton>
        )}
        <SecondaryButton
          className="margin-top"
          size="small"
          disabled={!statusIsEditable(model[statusAttr], model[idAttr])}
          onClick={() => this.onDelete(connectionType)}
        >
          {`Löschen`}
        </SecondaryButton>
      </div>
    );
  };
  render() {
    const {
      className,
      userCompany,
      kdlCompanyList,
      canReadAdminData,
      model,
      rows,
      vfEmailPermission,
    } = this.props;
    const {
      adConnection,
      ptConnection,
      ptUsername,
      ptPassword,
      ptStatus,
      ptStatus_id,
    } = this.state;
    return (
      <div className={className}>
        <PermissionResource
          resource={Resources.Organisation.WorkerWizard.ADConnections}
        >
          <div
            id="ad-zugaenge"
            className="w3-border-top neg-margin-big-lr"
          />
          <ADConnectionListForm
            ref={(ref) => (this.adConnection = ref)}
            className="pad-big-top"
            model={model}
            vfEmailPermission={vfEmailPermission}
            onChange={this.onADConnectionChange}
          />
        </PermissionResource>
        <PermissionResource
          resource={Resources.Organisation.WorkerWizard.PTConnections}
        >
          <PTConnectionForm
            model={model}
            adConnection={adConnection}
            ptConnection={ptConnection}
            ptUsername={ptUsername}
            ptPassword={ptPassword}
            ptStatus={ptStatus}
            ptStatus_id={ptStatus_id}
            setState={(...args) => this.setState(...args)}
            renderConnectionBtns={this.renderConnectionBtns}
            children={this.props.children}
            disabled={!statusIsEditable(ptStatus_id, model.pt_id, true)}
          />
        </PermissionResource>
        <PermissionResource
          resource={Resources.Organisation.WorkerWizard.DelphiConnections}
        >
          <div
            id="delphi-zugaenge"
            className="w3-border-top neg-margin-big-lr margin-big-top margin-btm"
          />
          <Connection
            ref={(ref) => (this.connections = ref)}
            className="pad-big-top"
            model={model.delphiConnections}
            kdlCompanyList={kdlCompanyList}
            userCompany={userCompany}
            disableDelphi={adConnection !== "Ja"}
            canReadAdminData={canReadAdminData}
            rows={rows}
          />
        </PermissionResource>
        <ConfirmationDialog ref={(ref) => (this.confirm = ref)} />
      </div>
    );
  }
}

const ADConnectionForm = ({
  model,
  adConnection,
  adUsername,
  adPassword,
  adStatus,
  adStatus_id,
  setADConnection,
  setState,
  disabled,
  renderConnectionBtns,
  children,
}) => {
  return (
    <>
      <div className="w3-border-top neg-margin-big-lr margin-btm" />
      <div id="ad-zugang" className="w3-cell-row">
        <div className="w3-cell w3-cell-top">
          <div className="w3-row neg-margin-lr">
            <div className="w3-col s2 pad-lr pad-top">
              <Label htmlFor="adConnection">{`AD-Zugang*`}</Label>
              <Dropdown
                id="adConnection"
                className="w3-block w3-border"
                value={adConnection}
                placeholder="Bitte auswählen"
                options={YesNoOptions}
                onChange={(event) => setADConnection(event.value)}
                showClear
                disabled={disabled || (adStatus_id !== STATUS_IDS.NEW)}
              />
            </div>
            <div className="w3-col s8 pad-lr">
              <div className="w3-row neg-margin-lr">
                <div className="w3-col s4 pad-lr pad-top">
                  <Label htmlFor="adUsername">{`AD-Kennung`}</Label>
                  <InputText
                    id="adUsername"
                    className="w3-block w3-border"
                    value={adUsername}
                    disabled={true}
                    onChange={(event) =>
                      setState({ adUsername: event.target.value })
                    }
                  />
                </div>
                <div className="w3-col s4 pad-lr pad-top">{children}</div>
                <PermissionResource
                  resource={
                    Resources.Organisation.WorkerWizard.ConnectionPasswords
                  }
                >
                  <div className="w3-col s4 pad-lr pad-top">
                    <Label htmlFor="adPassword">{`AD-Passwort`}</Label>
                    <InputText
                      id="adPassword"
                      className="w3-block w3-border"
                      value={adPassword}
                      disabled={true}
                      onChange={(event) =>
                        setState({ adPassword: event.target.value })
                      }
                    />
                  </div>
                </PermissionResource>
              </div>
            </div>
            <div className="w3-col s2 pad-lr pad-top">
              <Label htmlFor="adStatus">{`AD-Zugangstatus`}</Label>
              <InputText
                id="adStatus"
                className="w3-block w3-border"
                value={adStatus}
                disabled
              />
            </div>
          </div>
        </div>
        {!disabled && (
          <div
            className="w3-cell w3-cell-bottom alg-rht"
            style={{ width: "150px" }}
          >
            {renderConnectionBtns("AD", { ...model, adStatus_id })}
          </div>
        )}
      </div>
    </>
  );
};

const PTConnectionForm = ({
  model,
  adConnection,
  ptConnection,
  ptUsername,
  ptPassword,
  ptStatus,
  ptStatus_id,
  setState,
  renderConnectionBtns,
  readOnly,
  disabled: _disabled,
}) => {
  const disabled = _disabled || !statusIsEditable(ptStatus_id, model.pt_id, true);
  return (
    <>
      <div className="w3-border-top neg-margin-big-lr margin-big-top margin-btm" />
      <div id="pt-zugang" className="w3-cell-row">
        <div className="w3-cell w3-cell-top">
          <div className="w3-row neg-margin-lr">
            <div className="w3-col s2 pad-lr pad-top">
              <Label htmlFor="ptConnection">{`Prov.-tool-Zugang*`}</Label>
              <Dropdown
                id="ptConnection"
                className="w3-block w3-border"
                value={ptConnection}
                placeholder="Bitte auswählen"
                disabled={disabled || (adConnection !== "Ja") || (ptStatus_id !== STATUS_IDS.NEW)}
                options={YesNoOptions}
                onChange={(event) => setState({ ptConnection: event.value })}
                showClear
              />
            </div>
            <div className="w3-col s8 pad-lr">
              <div className="w3-row neg-margin-lr">
                <div className="w3-col s4 pad-lr pad-top">
                  <Label htmlFor="ptUsername">{`Prov.-tool-Kennung`}</Label>
                  <InputText
                    id="ptUsername"
                    className="w3-block w3-border"
                    value={ptUsername}
                    disabled={true}
                    onChange={(event) => setState({ ptUsername: event.value })}
                  />
                </div>
                <PermissionResource
                  resource={
                    Resources.Organisation.WorkerWizard.ConnectionPasswords
                  }
                >
                  <div className="w3-col s4 pad-lr pad-top">
                    <Label htmlFor="ptPassword">{`Prov.-tool-Passwort`}</Label>
                    <InputText
                      id="ptPassword"
                      className="w3-block w3-border"
                      value={ptPassword}
                      disabled={true}
                      onChange={(event) =>
                        setState({ ptPassword: event.target.value })
                      }
                    />
                  </div>
                </PermissionResource>
              </div>
            </div>
            <div className="w3-col s2 pad-lr pad-top">
              <Label htmlFor="ptStatus">{`PT-Zugangstatus`}</Label>
              <InputText
                id="ptStatus"
                className="w3-block w3-border"
                value={ptStatus}
                disabled
              />
            </div>
          </div>
        </div>
        {!_disabled && !readOnly && (
          <div
            className="w3-cell w3-cell-bottom alg-rht"
            style={{ width: "150px" }}
          >
            {renderConnectionBtns("PT", { ...model, ptStatus_id })}
          </div>
        )}
      </div>
    </>
  );
};

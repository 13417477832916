import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  DeleteIconButton,
  EditIconNavButton,
  ViewIconNavButton,
} from "../../../components/Buttons";
import {
  createSortById,
  scrollToTop,
  TableEmptyMessage,
  toUIDate,
} from "../../../components/Utils";
import SalesTablePageReport from "../../Reporting/components/SalesTablePageReport";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { loadNews, deleteArticle } from "../actions";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

const frontload = async (props) => {
  console.log("getNews()", props);
  await props.getNews({ categoryName: props.categoryName });
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.news.loading,
    newsSummaries: state.news.teasers,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getNews: (...args) => dispatch(loadNews(...args)),
  deleteArticle: (...args) => dispatch(deleteArticle(...args)),
});

class NewsArticleTable extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    newsSummaries: PropTypes.array,
    getNews: PropTypes.func.isRequired,
    deleteArticle: PropTypes.func.isRequired,
    rows: PropTypes.number,
    className: PropTypes.string,
    categoryName: PropTypes.string,
  };
  static defaultProps = {
    rows: 10,
    className: "",
    categoryName: "General",
  };
  constructor() {
    super();
    this.state = { first: 0 };
  }
  componentDidUpdate() {
    scrollToTop();
  }
  onPage = (event) => {
    this.setState({ first: event.first });
  };
  onEditArticle = (newsItem, history) => {
    console.log("Editing", newsItem);
    let url = `/news-service/beitrag/${encodeURIComponent(newsItem.id)}`;
    if (this.props.categoryName === "Sales") {
      url = `/sales/news-feed/beitrag/${encodeURIComponent(newsItem.id)}`;
    }
    history.push(url);
  };
  onDeleteArticle = (newsItem) => {
    this.confirm.show(
      true,
      {
        title: "Beitrag löschen",
        text: `Wollen Sie diesen Beitrag "${newsItem.title}" wirklich löschen?`,
      },
      () => this.props.deleteArticle(newsItem, this.props.categoryName)
    );
  };
  actionTemplate = (rowData) => {
    const NavButton = this.props.disabled
      ? ViewIconNavButton
      : EditIconNavButton;
    return (
      <div /* style={{display:"flex", gap:"4px"}} */>
        <NavButton
          id={`editNewsBtn${rowData.id}`}
          onClick={(history) => this.onEditArticle(rowData, history)}
        />
        <DeleteIconButton
          id={`deleteBtn${rowData.id}`}
          onClick={() => this.onDeleteArticle(rowData)}
          title="löschen"
        />
      </div>
    );
  };
  render() {
    const { className, isLoading, newsSummaries, rows } = this.props;
    // console.log('NewsArticleTable.render()', news);
    const data = (newsSummaries || [])
      .map((newsItem, index) => ({
        ...newsItem,
        index,
        changeDate: toUIDate(
          newsItem.modified_date || newsItem.publication_date
        ),
      }))
      // sort by id in descending order
      .sort(createSortById(true));
    return (
      <div className={`news-table clearfix ${className}`}>
        <DataTable
          value={data}
          rows={rows}
          first={this.state.first}
          onPage={this.onPage}
          paginator={data.length > rows}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={data.length}
              filteredRecords={data.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Beiträge"
              itemName="Beitrag"
              itemNamePlural="Beiträge"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={
            isLoading ? null : <TableEmptyMessage itemNamePlural="Beiträge" />
          }
        >
          <Column
            field="title"
            header="Titel"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
          />
          <Column
            field="visibility"
            header="Sichtbarkeit"
            className="alg-center"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{ width: "10em" }}
          />
          <Column
            field="changeDate"
            header="Zuletzt geändert"
            className="alg-center"
            sortable={data.length > 1}
            filter={data.length > rows}
            filterMatchMode="contains"
            style={{ width: "12em" }}
          />
          <Column
            body={this.actionTemplate}
            className="alg-center"
            style={{ width: "7.4em" }}
          />
        </DataTable>
        <ConfirmationDialog
          ref={(ref) => (this.confirm = ref)}
          // showReason={showItemDeleteReason || showItemCloseReason}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(NewsArticleTable)
);

import { Resources } from "../../Permissions";

const AppLinks = [
  { to: "/start", label: "Start", exact: true, resource: Resources.Start },
  {}, // technikLink,
  {}, // salesLink,
  {
    to: "/shop",
    label: "Web Shops",
    isVisibleForFlag: "isShopViewer",
    resource: Resources.Webshop.Page,
    subMenu: {
      className: "nav-level-1",
      links: [
        {
          to: "/shop/modems",
          label: "Modem-Logistik",
          isVisibleForFlag: "isLogisticsAssistant",
          resource: Resources.Webshop.Modems.Page,
          subMenu: {
            className: "nav-level-2",
            links: [
              {
                to: "/shop/modems/bestellungen",
                label: "Bestellungen",
                resource: Resources.Webshop.Modems.Orders,
              },
              {
                to: "/shop/modems/avise",
                label: "Modem Avise",
                resource: Resources.Webshop.Modems.ModemAvis,
              },
              {
                to: "/shop/modems/uebersicht",
                label: "Übersicht",
                resource: Resources.Webshop.Modems.Overview,
              },
              {
                to: "/shop/modems/produkte",
                label: "Produktverwaltung",
                resource: Resources.Webshop.Modems.Management,
              },
              {
                to: "/shop/modems/debitor-mapping",
                label: "Debitor-Mapping",
                resource: Resources.Webshop.Modems.DebitorMapping,
              },
            ],
          },
        },
        {
          to: "/shop/schliessmittel",
          label: "Schließmittel",
          isVisibleForFlag: "isLogisticsKeys",
          resource: Resources.Webshop.Keys.Page,
          subMenu: {
            className: "nav-level-2",
            links: [
              {
                to: "/shop/schliessmittel/bestellungen",
                label: "Bestellungen",
                resource: Resources.Webshop.Keys.Orders,
              },
              {
                to: "/shop/schliessmittel/uebersicht",
                label: "Übersicht",
                resource: Resources.Webshop.Keys.Overview,
              },
              {
                to: "/shop/schliessmittel/produkte",
                label: "Produktverwaltung",
                resource: Resources.Webshop.Keys.Management,
              },
            ],
          },
        },
        {
          to: "/shop/karten",
          label: "Karten/Flyer",
          isVisibleForFlag: "isLogisticsCards",
          resource: Resources.Webshop.Cards.Page,
          comments: "Karten und Flyer bestellen und Bestellungen verwalten",
          subMenu: {
            className: "nav-level-2",
            links: [
              {
                to: "/shop/karten/bestellungen",
                label: "Bestellungen",
                resource: Resources.Webshop.Cards.Orders,
              },
              {
                to: "/shop/karten/uebersicht",
                label: "Übersicht",
                resource: Resources.Webshop.Cards.Overview,
              },
              {
                to: "/shop/karten/produkte",
                label: "Produktverwaltung",
                resource: Resources.Webshop.Cards.Management,
              },
            ],
          },
        },
      ],
    },
  },
  {}, // organisationLink
  {
    to: "/news-service",
    label: "News",
    resource: Resources.News.Page,
    subMenu: {
      className: "nav-level-1",
      links: [
        {
          to: "/news-service/home",
          label: "Übersicht",
          resource: Resources.News.Overview,
        },
        {
          to: "/news-service/nachrichten",
          label: "News Einstellen",
          resource: Resources.News.MessagePanel,
        },
        {
          to: "/news-service/protokolle",
          label: "News Versandprotokolle",
          resource: Resources.News.Protocols,
        },
        {
          to: "/news-service/beitraege",
          label: "Wichtige Hinweise",
          resource: Resources.News.Articles,
        },
        {
          to: "/news-service/archiv",
          label: "Ad-hoc News Archiv",
          resource: Resources.News.Archive,
        },
      ],
    },
  },
  {
    to: "/aufgaben",
    label: "Aufgaben",
    resource: Resources.Tasks.Page,
    subMenu: {
      className: "nav-level-1",
      links: [
        {
          to: "/aufgaben/debitoren",
          label: "Debitoren",
          resource: Resources.Tasks.Debitors,
        },
        {
          to: "/aufgaben/it-zugaenge",
          label: "IT-Zugänge",
          resource: Resources.Tasks.ITConnections.Page,
          subMenu: {
            className: "nav-level-2",
            links: [
              {
                to: "/aufgaben/it-zugaenge/vodafone-accounts",
                label: "Vodafone Accounts",
                resource: Resources.Tasks.ITConnections.ADConnections,
              },
              {
                to: "/aufgaben/it-zugaenge/ms-authenticator",
                label: "MS Authenticator",
                resource: Resources.Tasks.ITConnections.MSAuthenticator,
              },
              {
                to: "/aufgaben/it-zugaenge/pt-zugaenge",
                label: "PT-Zugänge",
                resource: Resources.Tasks.ITConnections.PTConnections,
              },
              {
                to: "/aufgaben/it-zugaenge/delphi-zugaenge",
                label: "DELPHI-Zugänge",
                resource: Resources.Tasks.ITConnections.DelphiConnections,
              },
            ],
          },
        },
        {
          to: "/aufgaben/messgeraete",
          label: "Messgeräte",
          resource: Resources.Tasks.Devices,
        },
        {
          to: "/aufgaben/modems",
          label: "Modem-Logistik",
          resource: Resources.Tasks.Modems,
        },
        {
          to: "/aufgaben/schliessmittel",
          label: "Schließmittel",
          resource: Resources.Tasks.Keys,
        },
        {
          to: "/aufgaben/karten",
          label: "Karten/Flyer",
          resource: Resources.Tasks.Cards,
        },
        {
          to: "/aufgaben/reports",
          label: "Reports",
          resource: Resources.Tasks.Reports.Page,
          subMenu: {
            className: "nav-level-2",
            links: [
              {
                to: "/aufgaben/reports/my-techie",
                label: "MyTechie",
                resource: Resources.Tasks.Reports.MyTechie,
              },
            ],
          },
        },
        {
          to: "/aufgaben/benutzer",
          label: "Benutzerverwaltung",
          resource: Resources.Tasks.UserManagement.Page,
          subMenu: {
            className: "nav-level-2",
            links: [
              {
                to: "/aufgaben/benutzer/aenderung-kontaktdaten",
                label: "Änderung Kontaktdaten",
                resource: Resources.Tasks.UserManagement.Contact,
              },
              {
                to: "/aufgaben/benutzer/unversandte-emails-firmen",
                label: "Unversandte E-Mails: Firmen",
                resource: Resources.Tasks.UserManagement.CompanyEmails,
              },
              {
                to: "/aufgaben/benutzer/unversandte-emails-benutzer",
                label: "Unversandte E-Mails: Benutzer",
                resource: Resources.Tasks.UserManagement.EmployeeEmails,
              },
              {
                to: "/aufgaben/benutzer/aenderung-subunternehmen",
                label: "Änderung Subunternehmen",
                resource: Resources.Tasks.UserManagement.EmployeeCompany,
              },
              {
                to: "/aufgaben/benutzer/deaktivierung-mitarbeiter",
                label: "Mitarbeiter Deaktivieren",
                resource: Resources.Tasks.UserManagement.EmployeeDeactivation,
              },
            ],
          },
        },
      ],
    },
  },
];

export default AppLinks;

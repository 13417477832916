import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  STATUS_LABELS,
  STATUS_IDS,
  ACTION_IDS,
  statusIsEditable,
  TableEmptyMessage,
} from "../../../../components/Utils";
import { DeviceFormMenu } from "../../../../components/Buttons";
import ItemListManager from "../../../../components/ItemListManager";
import ADConnectionForm from "./ADConnectionForm";

export default class ADConnectionListForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({
      adConnection: PropTypes.string,
      adUsername: PropTypes.string,
      adPassword: PropTypes.string,
      adCellNumber: PropTypes.string,
      vf_email: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    vfEmailPermission: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
  };
  static defaultProps = {
    className: "",
    model: {
      adConnection: "",
      adUsername: "",
      adPassword: "",
      adCellNumber: "",
      vf_email: "",
    },
  };
  constructor(props) {
    super(props);
    const exists = props.model && props.model.adConnection === "Ja";
    this.state = {
      items: (exists ? [props.model] : []).map((item, index) => ({
        ...item,
        index,
      })),
      first: 0,
    };
  }
  getModel = () => {
    const { items } = this.state;
    return items[0];
  };
  validate = () => {
    const { items } = this.state;
    if (items.length === 0) {
      return this.props.model;
    }
    const { index, ...model } = items[0];
    const pModel = this.props.model || {};
    model.ad_id = pModel.ad_id;
    return model;
  };
  getModel = () => {
    const model = this.validate();
    return model;
  };
  onSave = (onHide, updating) => {
    const model = this.form.getModel();
    if (!model) return;
    model.adStatus_id = updating ? STATUS_IDS.UPDATING : STATUS_IDS.NEW;
    model.adAction_id = updating ? ACTION_IDS.UPDATE : ACTION_IDS.CREATE;
    const { items } = this.state;
    if (model.index !== undefined && items[model.index] !== undefined) {
      items[model.index] = model;
    } else {
      model.index = items.length;
      items.push(model);
    }
    this.setState({ items });
    this.props.onChange && this.props.onChange(model.adConnection);
    onHide && onHide();
  };
  onDelete = (selected) => {
    // console.log("deleting", selected)
    if (selected.id) {
      const { items } = this.state;
      if (items[selected.index]) {
        items[selected.index].adStatus_id = STATUS_IDS.DELETING;
        items[selected.index].adAction_id = ACTION_IDS.DELETE;
        items[selected.index].adStatus = null;
        this.setState({ items });
        this.props.onChange && this.props.onChange(items);
      } else {
        console.error(
          "<< vodafone connection to be deleted was not found",
          this.state.items
        );
      }
    } else {
      const items = [];
      for (const item of this.state.items) {
        if (item.index !== selected.index) {
          items.push(item);
        }
      }
      this.setState({ items });
      this.props.onChange && this.props.onChange(false);
    }
  };
  setADConnection = (value) => {
    this.setState({ adConnection: value });
  };
  render() {
    const { className, disabled, vfEmailPermission, model } = this.props;
    const { first } = this.state;
    const viewVFEmail = vfEmailPermission && vfEmailPermission.read;
    const editVFEmail = vfEmailPermission && vfEmailPermission.write;
    const onPage = (event) => this.setState({ first: event.first });

    const renderItemTable = (items, actionTemplate) => {
      const data = items.map((item, index) => ({
        ...item,
        index,
        adStatus:
          item.adStatus || STATUS_LABELS[item.adStatus_id || STATUS_IDS.NEW],
      }));
      return (
        <>
          <DataTable
            value={data}
            rows={1}
            first={first}
            onPage={onPage}
            emptyMessage={
              <TableEmptyMessage
                prefix="Kein"
                itemNamePlural="Vodafone Account"
                min
              />
            }
          >
            {viewVFEmail && (
              <Column field="vf_email" header="Vodafone E-Mail" />
            )}
            <Column field="adUsername" header="Kennung" />
            <Column field="adStatus" header="Status" />
            <Column
              body={actionTemplate}
              style={{ textAlign: "center", width: "8em" }}
            />
          </DataTable>
          <div
            className="pad-big-top"
            style={{ display: "flex", gap: "8px", alignItems: "center" }}
          >
            <Checkbox
              inputId={`msAuthentication`}
              checked={model.isAutheticatorRegistered}
              disabled={true}
              style={{ cursor: "default" }}
            ></Checkbox>
            <label htmlFor={`msAuthentication`} className="bold">
              {"Authenticator vorregistriert"}
            </label>
          </div>
        </>
      );
    };
    const renderItemForm = (item, onHide) => {
      const saveDisabled = item && item.status_id !== STATUS_IDS.NEW;
      return (
        <>
          <ADConnectionForm
            ref={(ref) => (this.form = ref)}
            model={item}
            vfEmailReadable={viewVFEmail}
            vfEmailEditable={editVFEmail}
            key={Math.random()}
          />
          <DeviceFormMenu
            className="margin-big-top w3-border-top pad-big w3-light-grey"
            style={{
              marginLeft: "-1em",
              marginRight: "-1em",
              marginBottom: "-1em",
            }}
            updateHidden
            onSave={() => this.onSave(onHide)}
            saveDisabled={saveDisabled}
            saveHidden={disabled || saveDisabled}
            saveBtnLabel="Beantragen"
            saveBtnName="saveVodafoneAccBtn"
            onCancel={onHide}
            cancelBtnLabel={disabled ? "Schließen" : undefined}
            cancelBtnName="cancelVodafoneAccBtn"
          />
        </>
      );
    };
    const getItemFormTitle = (connection) => {
      let title = "Vodafone Account bearbeiten";
      if (!connection) title = "Vodafone Account anlegen";
      return <h4 className="no-margin">{title}</h4>;
    };
    const isEditDisabled = (item) => true || !!item.ad_id || disabled;
    // const isDeleteDisabled = (item) =>(
    //   (item.id === undefined || item.id === null) || !statusIsEditable(item.adStatus_id, item.ad_id) || disabled
    // );
    const isDeleteDisabled = (item) => {
      if (item.id === undefined || item.id === null) {
        return false;
      }
      return !statusIsEditable(item.adStatus_id, item.ad_id) || disabled;
    };

    return (
      <ItemListManager
        key={Math.random()}
        itemPanelStyle={{ width: "480px" }}
        className={className}
        title={
          <h4 className="no-margin-top" style={{ height: "36px" }}>
            {"Vodafone Account"}
          </h4>
        }
        addBtnLabel="Hinzufügen"
        addBtnName="addAdConnectionBtn"
        addBtnDisabled={this.state.items.length === 1}
        addBtnHidden={disabled || this.state.items.length === 1}
        items={this.state.items}
        getItemFormTitle={getItemFormTitle}
        renderItemTable={renderItemTable}
        renderItemForm={renderItemForm}
        namePrefix="adConnection"
        isEditDisabled={isEditDisabled}
        hideDisabledEdit
        itemDeleteable={!disabled}
        hideDisabledDelete
        isDeleteDisabled={isDeleteDisabled}
        deleteTitle="Vodafone Account löschen"
        getDeleteText={(item) => {
          if (item.id === undefined || item.id === null) {
            return `Wollen Sie wirklich löschen?`;
          }
          return `Wollen Sie eine Löschung des Vodafone Accounts wirklich beantragen?`;
        }}
        onDelete={this.onDelete}
      />
    );
  }
}

import * as actionTypes from './actions';
import isArray from 'lodash/isArray';
import { LOGOUT_SUCCESS } from '../../redux/actions/auth';
import { notifySuccess, createSortByAttr, notifyError, openFile, isKDL, isSpecialKDL, isMasterCompany, createSortByLabel, isContractor, cleanUpList, cleanUpDropdownOptions } from '../../components/Utils';

const initialState = {
  teasers: [],
  article: null,
  loaded: false,
  protocols: [],
  archives: [],
  archiveFilter: {
    first: 0,
    rows: 10,
    keyword: null,
    fromDate: null,
    toDate: null,
    sortFields: {}
  },

  kdlCompanyOptions: [],
  dlCompanyOptions: [],
  contractorOptions: [],

  companyFootPrints: [],
  footPrints: [],
  footPrintOptions: [],
  footPrintGroups: [],
  footPrintGroupOptions: [],
  companyGroupOptions: [],
  message: null,

  signature: {
    name: "",
    text: "",
    image: "",
  },
};

const toOptionsList = item => ({ label: item.name, value: item.id })

export default (state = initialState, action) => {
  switch (action.type) {
    // loads news companies
    case actionTypes.LOAD_NEWS_COMPANIES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_NEWS_COMPANIES_SUCCESS: {
      const results = {};
      if (action.regions) {
        results.regions = regions;
        results.kdlCompanyOptions = cleanUpDropdownOptions(
          action.result
            .filter(item => isKDL(item) || isMasterCompany(item))
            .map(toOptionsList)
            .sort(createSortByLabel())
        );
        results.dlCompanyOptions = cleanUpDropdownOptions(
          action.result
            .filter(item => isSpecialKDL(item))
            .map(toOptionsList)
            .sort(createSortByLabel())
        );
      }
      else if (action.kdls || actions.dls) {
        // DLs
        const dls = action.result.filter(item => isSpecialKDL(item)).map(toOptionsList);
        if (dls.length > 0) {
          // console.log('<< found KDL dependent DLs:', dls);
          results.dlCompanyOptions = cleanUpDropdownOptions(
            [ ...state.dlCompanyOptions, ...dls ].sort(createSortByLabel())
          );
          // console.log('<< found KDL dependent DLs:', dls, 'Old DLs:', state.dlCompanyOptions, 'New DLs:', results.dlCompanyOptions);
        }
        // Firmen
        results.contractorOptions = cleanUpDropdownOptions(
          action.result
            .filter(item => isContractor(item))
            .map(toOptionsList)
            .sort(createSortByLabel())
        );
      }
      return {
        ...state,
        loading: false,
        ...results
      };
    }
    case actionTypes.LOAD_NEWS_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false
      };
    // // get news options
    // case actionTypes.LOAD_NEWS_OPTIONS:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case actionTypes.LOAD_NEWS_OPTIONS_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     ...action.result
    //   };
    // case actionTypes.LOAD_NEWS_OPTIONS_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.result
    //   };
    // load footprints
    case actionTypes.LOAD_FOOTPRINTS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_FOOTPRINTS_SUCCESS: {
      const footPrints = {};
      let companyFootPrints = [];
      const footPrintOptions = action.result.map(item => {
        footPrints[item.name] = item.text || "";
        companyFootPrints.push(item.name);
        return {
          ...item,
          label: item.name,
          value: item.name
        };
      });
      companyFootPrints = [...new Set(companyFootPrints)];
      const footPrintGroups = [{}, {}];
      const footPrintGroupOptions = [[], []];
      action.result.forEach(item => {
        const index = (item.text.indexOf("ad-hoc") >= 0) ? 0 : 1
        footPrintGroups[index][item.name] = item.text || "";
        footPrintGroupOptions[index].push({
          ...item,
          label: item.name,
          value: item.name
        });
      });
      return {
        ...state,
        loading: false,
        companyFootPrints,
        footPrints,
        footPrintOptions,
        footPrintGroups,
        footPrintGroupOptions
      };
    }
    case actionTypes.LOAD_FOOTPRINTS_FAILURE:
      notifyError({
        summary: `News Service`,
        detail: `Die Footprints konnten nicht geladen werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // company groups
    case actionTypes.LOAD_COMPANY_GROUPS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_COMPANY_GROUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        companyGroupOptions: action.result
      };
    }
    case actionTypes.LOAD_COMPANY_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };

    // send message
    case actionTypes.SEND_MESSAGE:
      return {
        ...state,
        sending: true
      };
    case actionTypes.SEND_MESSAGE_SUCCESS: {
      console.log('REDUX REDUCER: message sent', action);
      notifySuccess({
        summary: 'News Einstellen',
        detail: 'Die Nachricht wird gerade versendet. Sie können die Seite verlassen und das Protokoll später unter News-Versandkrotokolle finden.'
      });
      return {
        ...state,
        sending: false,
        kdlCompanyOptions: [],
        dlCompanyOptions: [],
        contractorOptions: []
      };
    }
    case actionTypes.SEND_MESSAGE_FAILURE: {
      console.log('Error:', action);
      const error = action.error || {};
      let reason = null;
      if (error.message) {
        switch(error.message) {
          case 'label.no.users.found':
            reason = `: es wurden keine Empfänger gefunden`;
            break;
          default:
            break;
        }
      }
      notifyError({
        summary: `News Einstellen`,
        detail: `Die Nachricht wurde nicht versandt${reason ? reason : ''}`
      })
      return {
        ...state,
        sending: false,
        error: action.result
      };
    }
    // load latest news
    case actionTypes.LOAD_NEWS_SUMMARIES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_NEWS_SUMMARIES_SUCCESS: {
      const teasers = action.result.map(item => ({
        ...item,
        visibility: item.isPrivate === 0 ? 'öffentlich' : 'privat'
      })).sort(createSortByAttr('publication_date', true));
      return {
        ...state,
        loading: false,
        teasers
      };
    }
    case actionTypes.LOAD_NEWS_SUMMARIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // load article
    case actionTypes.LOAD_ARTICLE:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_ARTICLE_SUCCESS: {
      // console.log('>> LOAD_ARTICLE_SUCCESS:', action);
      const article = isArray(action.result) ? action.result[0] : action.result;
      return {
        ...state,
        loading: false,
        article
      };
    }
    case actionTypes.LOAD_ARTICLE_FAILURE:
      notifyError({
        summary: 'Beitrag',
        detail: `Der Beitrag wurde nicht gefunden!`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // save article
    case actionTypes.SAVE_ARTICLE:
      return {
        ...state,
        article: null,
        saving: true
      };
    case actionTypes.SAVE_ARTICLE_SUCCESS: {
      console.log('>> SAVE_ARTICLE_SUCCESS:', action);
      const article = action.article;
      notifySuccess({
        summary: 'Beiträge',
        detail: `Der Beitrag '${article.title}' wurde erfolgreich ${article.id ? 'aktualisiert' : 'angelegt'}.`
      });
      article.id = action.result;
      return {
        ...state,
        saving: false,
        // article: action.result
        article
      };
    }
    case actionTypes.SAVE_ARTICLE_FAILURE: {
      const article = action.article;
      notifySuccess({
        summary: 'Beiträge',
        detail: `Der Beitrag '${article.title}' konnte nicht ${article.id ? 'aktualisiert' : 'angelegt'} werden!`
      });
      return {
        ...state,
        saving: false,
        article: null,
        error: action.result
      };
    }
    // cancel article
    case actionTypes.CANCEL_ARTICLE_WIZARD:
      return {
        ...state,
        article: null
      };
    // delete article
    case actionTypes.DELETE_ARTICLE:
      return {
        ...state,
        deleting: true
      };
    case actionTypes.DELETE_ARTICLE_SUCCESS: {
      notifySuccess({
        summary: 'Beitrag löschen',
        detail: `Der Beitrag '${action.article.title}' wurde erfolgreich gelöscht.`
      });
      return {
        ...state,
        deleting: false,
      };
    }
    case actionTypes.DELETE_ARTICLE_FAILURE: {
      notifyError({
        summary: 'Beitrag löschen',
        detail: `Der Beitrag '${action.article.title}' konnte nicht gelöscht werden!`
      });
      return {
        ...state,
        deleteing: false,
        error: action.result
      };
    }
    // save subscription
    case actionTypes.SAVE_SUBSCRIPTION:
      return {
        ...state,
        saving: true
      };
    case actionTypes.SAVE_SUBSCRIPTION_SUCCESS: {
      console.log('>> SAVE_SUBSCRIPTION_SUCCESS:', action);
      notifySuccess({
        summary: 'News Service',
        detail: `Ihre Einstellungen wurden erfolgreich geändert.`
      });
      return {
        ...state,
        saving: false
      };
    }
    case actionTypes.SAVE_SUBSCRIPTION_FAILURE:
      notifyError({
        summary: 'News Service',
        detail: `Ihre Einstellungen konnten nicht geändert werden!`
      });
      return {
        ...state,
        saving: false,
        error: action.result
      };
    
    case actionTypes.LOAD_ROLES:
      return {
        ...state,
        roles: action.result
      }
    // load protocols
    case actionTypes.LOAD_NEWS_PROTOCOLS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_NEWS_PROTOCOLS_SUCCESS:
      return {
        ...state,
        loading: false,
        protocols: action.result
      };
    case actionTypes.LOAD_NEWS_PROTOCOLS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // load protocol file
    case actionTypes.LOAD_NEWS_PROTOCOL_FILE:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_NEWS_PROTOCOL_FILE_SUCCESS: {
      const { filePath } = action.result || {};
      if (filePath) {
        notifySuccess({
          summary: `Versandprotokol`,
          detail: `Das geforderte Versandprotokol wurde erfolgreich generiert!`
        });
        openFile(filePath);
      }
      return {
        ...state,
        loading: false
      };
    }
    case actionTypes.LOAD_NEWS_PROTOCOL_FILE_FAILURE:
      notifyError({
        summary: `Versandprotokol`,
        detail: `Das geforderte Versandprotokol konnte nicht generiert werden!`
      });
      return {
        ...state,
        loading: false,
      };
    // load news archives
    case actionTypes.LOAD_ARCHIVE:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_ARCHIVE_SUCCESS: {
      return {
        ...state,
        loading: false,
        archives: action.result
      };
    }
    case actionTypes.LOAD_ARCHIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // load news archive message
    case actionTypes.LOAD_NEWS_MESSAGE:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_NEWS_MESSAGE_SUCCESS: {
      console.log("<< message loaded:", action.result);
      const { filePath } = action.result || {};
      if (filePath) {
        notifySuccess({
          summary: `VersandpArchiv-Nachrichtrotokol`,
          detail: `Die geforderte Nachricht wurde erfolgreich zum Download zur Verfügung gestellt!`
        });
        openFile(filePath);
      }
      return {
        ...state,
        loading: false,
        message: action.result
      };
    }
    case actionTypes.LOAD_NEWS_MESSAGE_FAILURE:
      notifyError({
        summary: `Archiv-Nachricht`,
        detail: `Die geforderte Nachricht kann nicht heruntergeladen werden!`
      });
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // search news archives
    case actionTypes.SEARCH_ARCHIVE:
      return {
        ...state,
        archiveFilter: {
          ...state.archiveFilter,
          ...action.filter
        },
        archiveQuery: action.query
      };
    // case actionTypes.SEARCH_ARCHIVE_SUCCESS: {
    //   console.log("<< message loaded:", action.result);
    //   return {
    //     ...state,
    //     loading: false,
    //     message: action.result
    //   };
    // }
    // case actionTypes.LOAD_NEWS_MESSAGE_FAILURE:
    //   notifyError({
    //     summary: `Archiv-Nachricht`,
    //     detail: `Die geforderte Nachricht kann nicht dargestellt werden!`
    //   });
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.result
    //   };
    // load signature
    case actionTypes.LOAD_SIGNATURE:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOAD_SIGNATURE_SUCCESS: {
      const signature = isArray(action.result) ? (action.result[0] || {}) : action.result;
      return {
        ...state,
        loading: false,
        signature: {
          ...state.signature,
          ...signature
        }
      };
    }
    case actionTypes.LOAD_SIGNATURE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.result
      };
    // save signature
    case actionTypes.SAVE_SIGNATURE:
      return {
        ...state,
        article: null,
        saving: true
      };
    case actionTypes.SAVE_SIGNATURE_SUCCESS: {
      const signature = action.signature;
      notifySuccess({
        summary: 'Signature speichern',
        detail: `Die Signatur wurde erfolgreich ${signature.id ? 'aktualisiert' : 'angelegt'}.`
      });
      signature.id = action.result;
      return {
        ...state,
        saving: false,
        signature
      };
    }
    case actionTypes.SAVE_SIGNATURE_FAILURE: {
      const signature = action.signature;
      notifyError({
        summary: 'Signature speichern',
        detail: `Die Signatur konnte nicht ${signature.id ? 'aktualisiert' : 'angelegt'} werden.`
      });
      return {
        ...state,
        saving: false,
        error: action.result
      };
    }
    // reinitialize on logout
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState
      };
    default:
      return {...state};
  }
}

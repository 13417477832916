import * as Test from './test';
import { loadUserProfile } from '../../redux/actions/auth';
import { createSortByLabel, encodeObjectToBase64 } from '../../components/Utils';

export const LOAD_NEWS_COMPANIES = 'news/companies/load';
export const LOAD_NEWS_COMPANIES_SUCCESS = 'news/companies/load/success';
export const LOAD_NEWS_COMPANIES_FAILURE = 'news/companies/load/failure';

export const LOAD_NEWS_OPTIONS = 'news/options/load';
export const LOAD_NEWS_OPTIONS_SUCCESS = 'news/options/load/success';
export const LOAD_NEWS_OPTIONS_FAILURE = 'news/options/load/failure';

export const SEND_MESSAGE = 'news/message/send';
export const SEND_MESSAGE_SUCCESS = 'news/message/send/success';
export const SEND_MESSAGE_FAILURE = 'news/message/send/failure';

export const LOAD_LATEST_NEWS = 'news/latest/load';
export const LOAD_LATEST_NEWS_SUCCESS = 'news/latest/load/success';
export const LOAD_LATEST_NEWS_FAILURE = 'news/latest/load/failure';

export const LOAD_NEWS_SUMMARIES = 'news/summaries/load';
export const LOAD_NEWS_SUMMARIES_SUCCESS = 'news/summaries/load/success';
export const LOAD_NEWS_SUMMARIES_FAILURE = 'news/summaries/load/failure';

export const LOAD_ARTICLE = 'news/article/load';
export const LOAD_ARTICLE_SUCCESS = 'news/article/load/success';
export const LOAD_ARTICLE_FAILURE = 'news/article/load/failure';

export const SAVE_ARTICLE = 'news/article/save';
export const SAVE_ARTICLE_SUCCESS = 'news/article/save/success';
export const SAVE_ARTICLE_FAILURE = 'news/article/save/failure';

export const DELETE_ARTICLE = 'news/article/delete';
export const DELETE_ARTICLE_SUCCESS = 'news/article/delete/success';
export const DELETE_ARTICLE_FAILURE = 'news/article/delete/failure';

export const CANCEL_ARTICLE_WIZARD = 'news/article/cancel';

export const SAVE_SUBSCRIPTION = 'news/subscription/save';
export const SAVE_SUBSCRIPTION_SUCCESS = 'news/subscription/save/success';
export const SAVE_SUBSCRIPTION_FAILURE = 'news/subscription/save/failure';

export const LOAD_ROLES = 'news/roles/load';

// export const LOAD_BASIC_ROLES = 'news/roles/basic/load';
// export const LOAD_BASIC_ROLES_SUCCESS = 'news/roles/basic/load/success';
// export const LOAD_BASIC_ROLES_FAILURE = 'news/roles/basic/load/failure';

export const LOAD_NEWS_PROTOCOLS = 'news/protocols/load';
export const LOAD_NEWS_PROTOCOLS_SUCCESS = 'news/protocols/load/success';
export const LOAD_NEWS_PROTOCOLS_FAILURE = 'news/protocols/load/failure';

export const LOAD_NEWS_PROTOCOL_FILE = 'news/protocols/file/load';
export const LOAD_NEWS_PROTOCOL_FILE_SUCCESS = 'news/protocols/file/load/success';
export const LOAD_NEWS_PROTOCOL_FILE_FAILURE = 'news/protocols/file/load/failure';

export const LOAD_FOOTPRINTS = 'news/footprints/load';
export const LOAD_FOOTPRINTS_SUCCESS = 'news/footprints/load/success';
export const LOAD_FOOTPRINTS_FAILURE = 'news/footprints/load/failure';

export const LOAD_COMPANY_GROUPS = 'news/companyGroups/load';
export const LOAD_COMPANY_GROUPS_SUCCESS = 'news/companyGroups/load/success';
export const LOAD_COMPANY_GROUPS_FAILURE = 'news/companyGroups/load/failure';

export const LOAD_ARCHIVE = 'news/archive/';
export const LOAD_ARCHIVE_SUCCESS = 'news/archive/load/success';
export const LOAD_ARCHIVE_FAILURE = 'news/archive/load/failure';

export const SEARCH_ARCHIVE = 'news/archive/search';
export const SEARCH_ARCHIVE_SUCCESS = 'news/archive/search/success';
export const SEARCH_ARCHIVE_FAILURE = 'news/archive/search/failure';

export const LOAD_NEWS_MESSAGE = 'news/message/load';
export const LOAD_NEWS_MESSAGE_SUCCESS = 'news/message/load/success';
export const LOAD_NEWS_MESSAGE_FAILURE = 'news/message/load/failure';

export const LOAD_SIGNATURE = 'news/signature/load';
export const LOAD_SIGNATURE_SUCCESS = 'news/article/signature/success';
export const LOAD_SIGNATURE_FAILURE = 'news/article/signature/failure';

export const SAVE_SIGNATURE = 'news/signature/save';
export const SAVE_SIGNATURE_SUCCESS = 'news/signature/save/success';
export const SAVE_SIGNATURE_FAILURE = 'news/signature/save/failure'


/**
 * Loads KDLs and DLs in a given list of regions
 * @param {Object} param0 Object containing regions
 * @returns 
 */
export const loadContractGivers = ({ regions }) => {
  console.log(`REDUX-ACTION: loadContractGivers() ...`, regions);
  const data = regions || [];
  if (data.length === 0) {
    return {
      type: LOAD_NEWS_COMPANIES_SUCCESS,
      result: [],
      regions
    };
  }
  return {
    types: [
      LOAD_NEWS_COMPANIES,
      LOAD_NEWS_COMPANIES_SUCCESS,
      LOAD_NEWS_COMPANIES_FAILURE
    ],
    promise: client => client.post(`/companies/regions`, { data }),
    regions
  };
};

/**
 * Load contractors of a given list of KDLS and DLs
 * @param {Object} param0 Object containing the KDLs and DLs
 * @returns 
 */
export const loadContractors = ({ kdls, dls }) => {
  console.log(`REDUX-ACTION: loadContractors() ...`, kdls, dls);
  const data = (kdls || []).concat(dls || []);
  if (data.length === 0) {
    return {
      type: LOAD_NEWS_COMPANIES_SUCCESS,
      result: [],
      kdls,
      dls
    };
  }
  return {
    types: [
      LOAD_NEWS_COMPANIES,
      LOAD_NEWS_COMPANIES_SUCCESS,
      LOAD_NEWS_COMPANIES_FAILURE
    ],
    promise: client => client.post(`/companies/contractorsByCompanies`, { data }),
    kdls,
    dls
  };
};

/**
 * Load all options required by the news form
 * @returns 
 */
export const loadOptions = () => {
  // return {
  //   type: LOAD_NEWS_OPTIONS_SUCCESS,
  //   result: {
  //     footPrintOptions: [
  //       { label: "VF West", value: "VF West" },
  //       { label: "VK Deutschland", value: "VK Deutschland" },
  //       { label: "Beide Footprints", value: "Beide Footprints" }
  //     ]
  //   }
  // };
  // // return {
  // //   types: [
  // //     LOAD_NEWS_OPTIONS,
  // //     LOAD_NEWS_OPTIONS_SUCCESS,
  // //     LOAD_NEWS_OPTIONS_FAILURE
  // //   ],
  // //   promise: client => client.get(`/news/options`)
  // // };
  return (dispatch) => {
    dispatch(loadFootprints());
    dispatch(loadCompanyGroups());
  };
};

export const loadFootprints = () => {
  console.log(`REDUX-ACTION: loadFootprints`);
  return {
    types: [
      LOAD_FOOTPRINTS,
      LOAD_FOOTPRINTS_SUCCESS,
      LOAD_FOOTPRINTS_FAILURE
    ],
    promise: client => client.get(`/news/footprints`)
  };
};

export const loadCompanyGroups = () => {
  return {
    type: LOAD_COMPANY_GROUPS_SUCCESS,
    result: [
      { value: "KDL", label: "Servicepartner" },
      { value: "DL", label: "Netzbetreiber WoWi Wunschpartner" },
    ] 
  }
};


export const sendMessage = ({ message, onSuccess }) => {
  console.log(`REDUX-ACTION: sendMessage() ...`, message);
  // onSuccess && onSuccess();
  // return { type: SEND_MESSAGE_SUCCESS };
  return {
    types: [SEND_MESSAGE, SEND_MESSAGE_SUCCESS, SEND_MESSAGE_FAILURE],
    promise: (client) => client.post(`/news/send`, { data: message }),
    onSuccess: () => onSuccess && onSuccess()
  }
};

export const loadNews = ({ categoryName, isPrivate, max }) => {
  console.log(`REDUX-ACTION: loadNews()`, categoryName, isPrivate);
  let visibilityName = 'null';
  if (isPrivate !== null && isPrivate !== undefined) {
    visibilityName = `${isPrivate}` === `0` ? 'Public' : 'Private';
  }
  return {
    types: [
      LOAD_NEWS_SUMMARIES,
      LOAD_NEWS_SUMMARIES_SUCCESS,
      LOAD_NEWS_SUMMARIES_FAILURE
    ],
    promise: (client) => client.get(`/news/categories/${categoryName}/visibilities/${visibilityName}/0`),
    max
  };
};

export const loadHomeNews = () => {
  // return loadNews({ categoryName: 'General', isPrivate: 0 });
  return {
    types: [
      LOAD_NEWS_SUMMARIES,
      LOAD_NEWS_SUMMARIES_SUCCESS,
      LOAD_NEWS_SUMMARIES_FAILURE
    ],
    promise: (client) => client.get(`/news/public/0`),
  };
};

export const loadWorkerNews = () => {
  return loadNews({ categoryName: 'General', isPrivate: 1 });
};

export const loadSalesNews = () => {
  return loadNews({ categoryName: 'Sales', isPrivate: null });
};

// export const loadLatest = ({ max, isPrivate }) => {
//   console.log(`REDUX-ACTION: loadLatest(${max}) ...`);
//   if (Test.TESTING) {
//     const teasers = Test.getLatest({ max, isPrivate });
//     return { type: LOAD_LATEST_NEWS_SUCCESS, result: teasers, max };
//   }
//   return {
//     types: [LOAD_LATEST_NEWS, LOAD_LATEST_NEWS_SUCCESS, LOAD_LATEST_NEWS_FAILURE],
//     promise: (client) => client.get(`/news/teasers/${max}/${isPrivate}`),
//     type
//   };
// };

export const loadArticle = ({ id, categoryName, isPrivate }) => {
  console.log(`REDUX-ACTION: loadArticle(${id}, ${categoryName}, ${isPrivate}, ) ...`);
  if (id === 'neu') {
    const article = {
      title: '',
      subtitle: '',
      isPrivate: 1,
      category_id: 1,
      body: ''
    };
    return { type: LOAD_ARTICLE_SUCCESS, result: article };
  }
  let visibilityName = 'null';
  if (isPrivate !== null && isPrivate !== undefined) {
    visibilityName = isPrivate ? 'Private' : 'Public';
  }
  let apiEndpoint = `categories/${categoryName}/visibilities/${visibilityName}`;
  if (isPrivate === false) {
    apiEndpoint = `public`;
  }
  return {
    types: [
      LOAD_ARTICLE,
      LOAD_ARTICLE_SUCCESS,
      LOAD_ARTICLE_FAILURE
    ],
    promise: (client) => client.get(`/news/${apiEndpoint}/${id}`)
  };
};

export const cancelArticle = () => ({ type: CANCEL_ARTICLE_WIZARD });

export const saveArticle = (article, onSuccess) => {
  console.log(`REDUX-ACTION: saveArticle()`);
  return (dispatch, getState) => {
    const user = getState().auth.user;
    article.created_by_user_id = user.id;
    // console.log('Saving', article);
    dispatch({
      types: [SAVE_ARTICLE, SAVE_ARTICLE_SUCCESS, SAVE_ARTICLE_FAILURE],
      promise: (client) => client.put(`/news`, { data: article }),
      onSuccess: () => onSuccess && onSuccess(),
      article
    });
  };
};

export const deleteArticle = (article, categoryName, onSuccess) => {
  return {
    types: [
      DELETE_ARTICLE,
      DELETE_ARTICLE_SUCCESS,
      DELETE_ARTICLE_FAILURE
    ],
    promise: (client) => client.del(`/news/deleteNewsMessage/${article.id}`),
    onSuccess: (dispatch) => {
      dispatch(loadNews({ categoryName }));
      onSuccess && onSuccess();
    },
    article,
  };
};

export const saveSubscription = (data, onSuccess) => {
  console.log(`REDUX-ACTION: saveSubscription()`, data);
  return (dispatch, getState) => {
    const user = getState().auth.user;
    // data.user_id = user.id;
    dispatch({
      types: [SAVE_SUBSCRIPTION, SAVE_SUBSCRIPTION_SUCCESS, SAVE_SUBSCRIPTION_FAILURE],
      promise: (client) => client.put(`/companies/news-settings`, { data }),
      onSuccess: (dispatch) => {
        dispatch(loadUserProfile(user));
        onSuccess && onSuccess()
      },
      data
    });
  };
}

/**
 * fetches all user roles (basic and extended)
 */
export const loadRoles = () => {
  const onSuccess = (dispatch, getState, basicRoles) => {
    // merge basic and extended roles
    const mergeResults = (roles1, roles2) => {
      console.log('REDUX ACTION - roles:', roles1, roles2);
      return dispatch({
        type: LOAD_ROLES,
        result: roles1.concat(roles2)
          // .filter(item => (item.admin_only === 0))
          .sort(createSortByLabel())
      })
    };
    // fetch extended roles
    return dispatch({
      types: [
        'news/extendedRoles',
        'news/extendedRoles/success',
        'news/extendedRoles/failure'
      ],
      promise: (client) => client.get(`/companies/extendedRoles/0`),
      onSuccess: (_dispatch, _getState, extendedRoles) => {
        return mergeResults(basicRoles, extendedRoles);
      }
    });
  }
  // fetch basic roles
  return {
    types: [
      'news/basicRoles',
      'news/basicRoles/success',
      'news/basicRoles/failure'
    ],
    promise: (client) => client.get(`/companies/basicRoles/0`),
    onSuccess
  };
}


export const loadProtocols = () => {
  console.log(`REDUX-ACTION: loadProtocols()`);
  return {
    types: [
      LOAD_NEWS_PROTOCOLS,
      LOAD_NEWS_PROTOCOLS_SUCCESS,
      LOAD_NEWS_PROTOCOLS_FAILURE
    ],
    promise: (client) => client.get(`/news/protocols`)
  };
};


export const loadProtocolFile = ({ id }) => {
  console.log(`REDUX-ACTION: loadProtocolFile(${id})`);
  return {
    types: [
      LOAD_NEWS_PROTOCOL_FILE,
      LOAD_NEWS_PROTOCOL_FILE_SUCCESS,
      LOAD_NEWS_PROTOCOL_FILE_FAILURE
    ],
    promise: (client) => client.get(`/news/protocols/csv/${id}`),
    // promise: (client) => client.get(`/news/protocols/pdf/${id}`),
  };
};

export const loadNewsArchives = ({ filter, query }, onSuccess) => {
  console.log(`REDUX-ACTION: loadNewsArchives():`, filter);
  let filterJsonStringBase64 = '';
  if (filter) {
    filterJsonStringBase64=encodeObjectToBase64(filter);
  }
  return {
    types: [
      LOAD_ARCHIVE,
      LOAD_ARCHIVE_SUCCESS,
      LOAD_ARCHIVE_FAILURE
    ],
    promise: (client) => client.get(`/news/search?filter=${filterJsonStringBase64}`),
    onSuccess: () => onSuccess && onSuccess()
  };
};

export const searchNewsArchives = ({ query, filter }) => {
  console.log(`REDUX-ACTION: searchNewsArchives() filter:`, filter);
  return { type: SEARCH_ARCHIVE, query, filter };
  // return {
  //   types: [
  //     SEARCH_ARCHIVE,
  //     SEARCH_ARCHIVE_SUCCESS,
  //     SEARCH_ARCHIVE_FAILURE
  //   ],
  //   promise: client => client.get(`/news/archives/search?${query}`),
  //   data
  // };
};

export const loadNewsMessage = ({ id }) => {
  console.log(`REDUX-ACTION: loadNewsMessage(id: ${id})`);
  return {
    types: [
      LOAD_NEWS_MESSAGE,
      LOAD_NEWS_MESSAGE_SUCCESS,
      LOAD_NEWS_MESSAGE_FAILURE
    ],
    // // promise: (client) => client.get(`/news/archives/${id}`),
    // promise: (client) => client.get(`/news/protocols/csv/${id}`),
    promise: (client) => client.get(`/news/protocols/pdf/${id}`),
  };
};

export const loadSignature = () => {
  return {
    types: [
      LOAD_SIGNATURE,
      LOAD_SIGNATURE_SUCCESS,
      LOAD_SIGNATURE_FAILURE
    ],
    promise: (client) => client.get(`/news/signatures/1`),
  };
};

export const saveSignature = (signature, onSuccess) => {
  console.log(`REDUX-ACTION: saveSignature()`, signature);
  return (dispatch, getState) => {
    const user = getState().auth.user;
    signature.id = 1;
    signature.created_by_user_id = user.id;
    dispatch({
      types: [
        SAVE_SIGNATURE,
        SAVE_SIGNATURE_SUCCESS,
        SAVE_SIGNATURE_FAILURE
      ],
      promise: (client) => client.put(`/news/signatures`, { data: signature }),
      onSuccess: () => onSuccess && onSuccess(),
      signature
    });
  };
};
